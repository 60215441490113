<template>
  <fishClicker></fishClicker>
  <indexMessage msg="Welcome to the FishZone"/>
  <indexMessage msg2="This Site is a WIP, come back later."/>
  <audio loop autoplay preload="auto">
    <source src="./assets/main.ogg" type="audio/ogg" >
    Your browser does not support the audio element.
  </audio>
</template>

<script>
import indexMessage from './components/indexMessage.vue'
import fishClicker from './components/fishClicker.vue'

export default {
  name: 'App',
  components: {
    indexMessage,
    fishClicker
  }
}
</script>

<style>
#app {
  font-family: 'Times New Roman', Times, serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #4c2c50;
  margin-top: 40px;
  margin-bottom: 20px;
}
</style>
