<script setup>
    import { ref } from 'vue'
    const count = ref(0)   
    function increment(){
        count.value++
    }
</script>
<template>
<button @click="increment">
         <img alt= "fish" src = "./assets/Xeno.gif">
    </button>
    <h3>You've clicked {{ count }} times!</h3>
</template>
<style>
#clicker {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #4c2c50;
  background-color: darkgrey;
  margin-top: 40px;
  margin-bottom: 20px;
  
}
</style>