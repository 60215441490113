<template>
    <div class="clicker">
        <button @click='increment'>
            <img src="../assets/Xeno.gif">
        </button>
        <h2>You've clicked {{ count }} times!</h2>
    </div>
</template>
  
<script>


export default {
    name: 'fishClicker',
    methods: {
        increment() {
            this.count++
        }
    },
    data() {
        return {
            count: 0
        }
    }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
    margin: 40px 0 0;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: inline-block;
    margin: 0 10px;
}

a {
    color: #42b983;
}
</style>
  